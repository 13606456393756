/* You can add global styles to this file, and also import other style files */
@import "./variables";
@import '~ag-grid-community/styles//ag-grid.css';
@import '~ag-grid-community/styles//ag-theme-alpine.css';
@import '~ag-grid-community/styles//ag-theme-material.css';


html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 13px !important;
}

.scrollbar {
  scrollbar-width: thin;
  /* "auto" or "thin"  */
  scrollbar-color: grey lightgrey;
  /* scroll thumb & track */
}

.tooltip-settings {
  margin-left: 30px;
}

.filter-modifier-header{
  min-height: 70px !important;
}

.tooltip-eye {
  margin-left: 70px;
}

.tooltip-filter {
  margin-left: 75px;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
  height: 8px;
}

.scrollbar::-webkit-scrollbar-track {
  background: lightgrey;
  /* color of the tracking area */
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: grey;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 2px solid grey;
  /* creates padding around scroll thumb */
}

.mat-drawer-inner-container {
  scrollbar-width: thin !important;
  /* "auto" or "thin"  */
  scrollbar-color: grey lightgrey !important;
  /* scroll thumb & track */
}

.mat-drawer-inner-container::-webkit-scrollbar {
  width: 8px !important;
  /* width of the entire scrollbar */
  height: 8px !important;
}

.mat-drawer-inner-container::-webkit-scrollbar-track {
  background: lightgrey !important;
  /* color of the tracking area */
}

.mat-drawer-inner-container::-webkit-scrollbar-thumb {
  background-color: grey !important;
  /* color of the scroll thumb */
  border-radius: 20px !important;
  /* roundness of the scroll thumb */
  border: 2px solid grey !important;
  /* creates padding around scroll thumb */
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  min-width: 1.75rem !important;
  line-height: 2rem !important;
  padding: 0 0.625rem !important;
  border-radius: $global-border-radius !important;
  min-height: 3rem !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px !important;
  height: 40px !important;
  padding: 0 0.625rem !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled){
  background-color: #b7e1f4 !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__action--primary{
  padding-left: 0px !important;
}

.chart-legend .legend-title{
  display: none !important;
}

.chart-legend .legend-labels{
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap !important;
  // width: auto !important;
}
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  font-size: 0.8125rem !important;
  font-family: "Mulish, sans-serif" !important;
  font-weight: 500 !important;
}

.mat-form-field-appearance-fill .mdc-line-ripple {
  display: none !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-bottom: 8px !important;
  min-height: 3rem !important;
}

.mat-form-field-appearance-fill .mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.mat-form-field-appearance-fill:not(.mat-form-field-disabled) .mat-form-field-flex:hover~.mdc-line-ripple {
  opacity: 0 !important;
}

.mat-expansion-panel-header {
  padding: 0 1rem !important;
  height: 2.5rem !important;
  font-size: 15px !important;
}

.mat-expansion-panel-body {
  padding: 0 1rem !important;
}

th.mat-mdc-header-cell {
  border-bottom-width: 0px !important;
}

th.mat-sort-header {
  border-bottom-width: 0px !important;
}

.mat-mdc-option {
  font-size: 13.33px !important;
  min-height: 38px !important;
}

.mat-mdc-optgroup-label .mdc-list-item__primary-text {
  font: 500 13px/24px Mulish, sans-serif !important;
  font-weight: 700 !important;
  color: #0000008a
}

.mdc-menu-surface.mat-mdc-select-panel {
  min-width: calc(100% + 92px) !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 20px !important;
}

.mat-mdc-unelevated-button:not(:disabled) {
  bottom: 0px !important;
}

.mat-mdc-text-field-wrapper {
  height: 47px !important;
  flex: 1 !important;
}

.align-items-center {
  align-items: none !important;
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-size: 10px !important;
  letter-spacing: normal !important;
}

.mat-mdc-button>.mat-icon {
  font-size: 1.5rem !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.mat-mdc-form-field-input-control {
  font-size: 12px !important;
}

.mat-mdc-form-field,
.mat-mdc-floating-label,
.mat-mdc-select {
  font-size: 13px !important;
}

.mdc-evolution-chip__graphic.mat-mdc-chip-graphic {
  display: none !important;
}

.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
  display: flex !important;
  align-items: center !important;
}

.mdc-list-item__primary-text {
  width: 100% !important;
}

.mat-mdc-tab-labels div#mat-tab-label-0-1{
  margin-left: none !important;
}

.mdc-evolution-chip__action.mat-mdc-chip-action {
  padding-right: 0 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
  -webkit-tap-highlight-color: none;
}

.mat-mdc-select-arrow svg {
  top: 140% !important;
}

.mat-mdc-select-arrow-wrapper {
  margin-left: 4px !important;
  margin-top: -5px !important;
}

mat-icon.mat-icon.notranslate.search-icon.material-icons.mat-ligature-font.mat-icon-no-color.ng-star-inserted {
  top: 40% !important;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(13px * .83) /20px Mulish, sans-serif !important;
}


.mat-mdc-form-field.mat-mdc-form-field-type-mat-select {
  -webkit-tap-highlight-color: none !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
}

.mat-mdc-tab-disabled {
  opacity: 1 !important;
  cursor: default !important;

  &:hover {
    background: whitesmoke !important;
  }
}

.margin-exception {
  margin-bottom: 10px !important;
}

.mat-mdc-card-content:first-child {
  display: flex;
  flex-direction: column;
}


.mat-icon {
  overflow: visible !important;
}

.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper.mdc-text-field:not(.mdc-text-field--no-label) {
  max-height: 50px !important;
}

.mat-expansion-panel-content {
  font: 400 12px/20px Mulish, sans-serif !important;
}

.mat-mdc-card-content {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f;
  padding: 16px !important;
}

#filter-text-box {
  font-size: 14px !important;
}

.mat-mdc-optgroup-label {
  align-items: none !important;
  min-height: 28px !important;
}

.mat-mdc-card-outlined {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f !important;
  border-color: transparent !important;
}

.mdc-button .mdc-button__label {
  font-family: Mulish, sans-serif;
}


.mdc-evolution-chip__text-label {
  white-space: normal !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--selectable:not(.mdc-evolution-chip--with-primary-icon) {
  height: unset !important;
  padding: 2px 8px !important;
}

.mat-input-element {
  background: transparent;
}

.mat-select-search-input[_ngcontent-ng-c1811315641] {
  border-bottom: 1px solid #00000021 !important;
}

.mat-ink-bar {
  padding: 5px 0px;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border: 0.5px solid #c1e1ff !important
}

.mat-mdc-tab-labels {
  border-bottom: 1px solid #00000021;
}

.mat-mdc-card-content {
  box-shadow: none !important;
}

.cdk-overlay-container:empty {
  display: none !important;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none !important;
}

// .cdk-overlay-pane {
//   width: 200px !important;
//   justify-content: flex-end;
// }


.color-sel-optgroup .mat-mdc-optgroup-label {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-size: 13px !important;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  box-shadow: none !important
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: black !important
}

@screen-sm-min =576px,

@media only screen and (max-width: @screen-sm-min + 100px) {
  .ant-calendar-range {
    width: 276px;

    .ant-calendar-range-part {
      width: 100%;
    }

    .ant-calendar-range-right {
      float: left;
      border-top: 1px solid #e8e8e8;
    }
  }
}

.confirmation-popup {
  width: 400px !important;
}

// .legend-title[_ngcontent-ng-c547292780] {
//   display: flex !important;
//   flex-direction: row !important;
// }

// p {
//   color: black !important;
// }

.pdf-viewer-panel .mdc-dialog__surface {
  background-color: rgba(0, 0, 0, 0.2) !important;
}